import React, { lazy } from 'react';
import { IdentificationModalWrapper } from '@fiverr-private/identification_core';

const LazyIdentificationForm = lazy(
    () => import(/* webpackChunkName: "LazyIdentificationBusinessForm" */ '../../components/IdentificationForm')
);

interface IdentificationModalProps {
    googleClientId: string;
    inDolphinsIdentificationBusinessRollout: boolean;
}

export const IdentificationModal: React.FC<IdentificationModalProps> = ({
    googleClientId,
    inDolphinsIdentificationBusinessRollout,
}) => (
    <IdentificationModalWrapper
        googleClientId={googleClientId}
        closeOnOverlayClick={false}
        inDolphinsIdentificationBusinessRollout={inDolphinsIdentificationBusinessRollout}
        renderLazyComponent={({
            isModal,
            isModalOpen,
            closeModal,
            mode,
            redirectUrl,
            email,
            username,
            isSticky,
            blockedActionType,
            bigQueryEnrichment,
            mixpanelEnrichment,
            afterSigningSuccess,
            isSocialFlow,
            stepId,
            skipActivation,
            source,
        }) => (
            <LazyIdentificationForm
                {...{
                    isModal,
                    isModalOpen,
                    closeModal,
                    mode,
                    redirectUrl,
                    email,
                    username,
                    isSticky,
                    blockedActionType,
                    bigQueryEnrichment,
                    mixpanelEnrichment,
                    afterSigningSuccess,
                    isSocialFlow,
                    stepId,
                    skipActivation,
                    source,
                }}
            />
        )}
    />
);
